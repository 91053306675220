import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import bdfullImg from "../../images/projectsfull/bdmyshopifull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>BD MyShopi</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="BD MyShopi"
              prokectfulldescription="For this potential EntityOne customer I designed a website with reusable building blocks. They wanted a base theme that could be used to build multiple sites. Whatever the content they were able to build a website from scratch with these pre-developed building blocks."
              projectclient="BD MyShopi"
              projectrole="Web Design"
              projectdate="2019"
              projectfullimg={<div className="project--img--inner"><img data-src={bdfullImg} className="project--img lazyload" alt="BD MyShopi" /></div>}
              projectnextlink="/projects/biocheck"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;